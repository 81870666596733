import React from 'react';
import { useState } from 'react';
import { EventHandler } from 'react';
import '../styles/folder.css'
import folderIcon from '../assets/folder-icon.webp'
import hardDriveIcon from '../assets/drive-icon.webp'
import Draggable from 'react-draggable';
import { navigate } from 'gatsby';

const iconStyle = "folder-icon"
const titleStyle = "folder-title"
let isDragging = false;

const handleDrag = () => {
    isDragging = true;
}

const handleStop = () => {
    setTimeout(() => { isDragging = false; }, 50)
}

const handleClick = (nav) => {
    if (!isDragging) {
        navigate(nav)
    }
}

const folder = ({ name, path }) => {
    return (
        <Draggable
            onDrag={handleDrag}
            onStop={handleStop}>
            <div className="folder" onClick={() => handleClick(path)} onTouchStart={() => setTimeout(() => { handleClick(path) }, 50)}>
                <img src={folderIcon} className={iconStyle} />
                <h3 className={titleStyle}>{name}</h3>
            </div>
        </Draggable>
    );
};

export default folder;