import * as React from "react"
import { Helmet } from "react-helmet"
import '../styles/main.css'
import SpinningHeadGIF from '../assets/spinningman.gif'
import Folder from "../components/Folder"
import Draggable from "react-draggable"
import MenuBar from "../components/MenuBar"
import HardDrive from "../components/HardDrive"
import ContactIcon from "../components/ContactIcon"
import Dock from "../components/Dock"
import HysgGIF from '../assets/hysg.gif'




// markup
const IndexPage = () => {

  const [playing, setPlaying] = React.useState(false);

  let isSuspended = false;

  let isPlaying = false;

  function handleSuspend() {
    isSuspended = true;
  }

  function handlePlay() {
    isPlaying = true;
  }

  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="black" />
        <title>HYSG</title>
        <link rel="canonical" href="https:/haveyoustoppedgrowing.com/" />
      </Helmet>
      <MenuBar />
      <div className="main">
        {/*<Draggable><div><h1 className="title">have you <br />stopped growing?</h1></div></Draggable>*/}
        <div className="top">
          <Draggable><div className=""><img className="hysg-gif" src={HysgGIF} /></div></Draggable>
          <Draggable><div className="spinning-head">
            <img className="spinning-head-video" src={SpinningHeadGIF} />
          </div></Draggable>
        </div>
        <div className="folders">
          <Folder name="shop" path="/shop" />
          <div className="pos2"><HardDrive name="portfolio" path="/portfolio" /></div>
          <ContactIcon name="inquiries" path="/inquiries" />
        </div>
      </div>
      <Dock />
    </main>
  )
}

export default IndexPage
